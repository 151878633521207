<template>
  <div class="download-from-store" v-show="showDownloadFromStore">
    <div class="app-ios" v-if="this.$isOS('ios')" @click="openIos">
      <div class="app-logo-container">
        <img class="app-logo" src="@/assets/images/logo/1024.png" alt="Logo">
      </div>
      <div>
        Pobierz naszą apkę na swojego iPhona
      </div>
      <div>
        <div class="close" @click.stop="closeDownload">
          <i class="far fa-times"></i>
        </div>
      </div>
    </div>
    <div class="app-android" v-if="this.$isOS('android')" @click="openAndroid">
      <div class="app-logo-container">
        <img class="app-logo" src="@/assets/images/logo/1024.png" alt="Logo">
      </div>
      <div>
        Pobierz naszą apkę na swój telefon
      </div>
      <div>
        <div class="close" @click="closeDownload">
          <i class="far fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DownloadFromStore",
  computed: {
    ...mapGetters([
      'showDownloadFromStore'
    ]),
  },
  methods: {
    closeDownload() {
      this.$store.dispatch('setShowDownloadFromStore', false);
    },
    openIos() {
      window.open('https://apps.apple.com/pl/app/fastcat/id1559723223?l=pl');
    },
    openAndroid() {
      window.open('https://play.google.com/store/apps/details?id=com.fastcat');
    },
  },
}
</script>

<style scoped lang="scss">
.download-from-store {
  .app-ios, .app-android {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary);
    color: var(--white);
    height: calc(64px + 12px);
  }
  .app-logo-container {
    margin-top: 6px;
    padding-left: 6px;
    .app-logo {
      width: 64px;
      height: 64px;
      border-radius: var(--border-radius);
    }
  }
  .close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 6px;
    height: 64px;
    font-size: 20px;
  }
}
</style>
