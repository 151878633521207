<template>
  <div class="sticky-header" :style="stickyHeaderStyles">
    <sequential-entrance animation="animate__fadeInLeft" tag="div">
      <div class="animate__animated logo_container" @click="$router.push({name: 'Home'})">
        <Logo mini/>
        <span class="logo_title" v-if="!withAddress || !isMobile">Fastcat</span>
      </div>
    </sequential-entrance>
    <sequential-entrance animation="animate__fadeInUp" tag="div">
      <div class="animate__animated address_container" v-if="title">
        <div class="address_content">
          <span>{{ title }}</span>
        </div>
      </div>
      <div class="animate__animated address_container" v-else>
        <div class="address_content" v-show="withAddress" @click="$store.commit('switchShowSearchAddress')">
          <span v-if="!address.street || !address.postcode || !address.city || !address.house_nr">Wybierz...</span>
          <span v-else>{{ address.street }} {{ address.house_nr }}{{ address.apartment_nr ? `/${address.apartment_nr}` : '' }}, {{ address.city }}</span>
        </div>
      </div>
    </sequential-entrance>
    <sequential-entrance animation="animate__fadeInRight" tag="div">
      <div class="animate__animated menu_container">
        <div class="menu_content" v-show="withMenu">
          <div class="menu_button" @click="$store.commit('setShowDrawer', true)">
            <i class="far fa-bars"></i>
          </div>
        </div>
      </div>
    </sequential-entrance>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import {mapGetters} from "vuex";

export default {
  name: "StickyHeader",
  components: {
    Logo,
  },
  props: {
    withAddress: Boolean,
    withMenu: Boolean,
    title: String,
    backgroundImage: String,
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'address',
    ]),
    stickyHeaderStyles() {
      let ar = {};
      if (this.backgroundImage) {
        ar['background-image'] = `url('${this.$asset(this.backgroundImage)}')`;
      }
      return ar;
    }
  },
};
</script>

<style scoped lang="scss">
  .sticky-header {
    background: var(--default-background);
    background-size: cover;
    background-position: center;
    width: calc(100% - 20px);
    height: 60px;
    top: 0;
    position: sticky;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    box-shadow: var(--shadow-one);

    .logo_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;
      height: 60px;
      cursor: pointer;
      background-color: var(--default-background);
      transition: background-color .2s ease;
      &:hover, &:focus {
        background-color: var(--input-background-secondary);
      }
    }
    .logo_title {
      margin-left: 20px;
    }
    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .address_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .address_content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: var(--input-background-secondary);
      padding: 10px 15px;
      border-radius: 34px;
      width: 100%;
      max-width: 600px;
      cursor: pointer;
      transition: opacity .2s ease;
      &:hover, &:focus {
        opacity: 0.75;
      }
    }
    .menu_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .menu_button {
        height: 60px;
        width: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: var(--default-background);
        transition: background-color .2s ease;
        &:hover, &:focus {
          background-color: var(--input-background-secondary);
        }
      }
    }
  }
</style>
