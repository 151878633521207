<template>
  <div class="page page--login animated_content">
    <SeqEntrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated"/>
      <div class="box-container animate__animated">
        <div class="box-content">
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <div class="animate__animated">
              <p>Logowanie</p>
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  name="login"
                  label="Login"
                  placeholder="Login / e-mail"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  @keypress.enter="login"
                  name="password"
                  label="Hasło"
                  placeholder="Hasło"
                  inputType="password"
                  required
              />
            </div>
            <div class="password-recovery animate__animated">
              <span @click="toPasswordRecovery"><small>Zapomniałeś hasła?</small></span>
            </div>
            <div class="animate__animated">
              <Btn @click="login" :disabled="!formIsValid">Zaloguj się</Btn>
            </div>
            <div class="animate__animated">
              <p style="text-align: left; font-size: 13px;"><span class="required-star">*</span> Pole wymagane</p>
            </div>
          </SeqEntrance>
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </SeqEntrance>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import TextField from "@/components/TextField";
import CheckboxField from "@/components/CheckboxField";
import SeqEntrance from "@/components/SeqEntrance";

export default {
  name: "Login",
  components: {SeqEntrance, CheckboxField, TextField, PlainFooter, StickyHeader, Logo, Btn},
  data() {
    return {
      form: {
        login: '',
        password: '',
      },
      formIsValid: false,
    };
  },
  methods: {
    openInNewCard(link) {
      window.open(link, '_blank');
    },
    toPasswordRecovery() {
      this.$router.push({ name: 'PasswordRecovery' });
    },
    validateForm() {
      let ret = true;
      if (!this.form.login) {
        ret = false;
      } else if (this.form.login.length < 3) {
        ret = false;
      }
      if (!this.form.password) {
        ret = false;
      } else if (this.form.password.length < 6) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    login() {
      if (!this.validateForm()) {
        this.$toast.error(`Formularz zawiera błędy`);
        return;
      }
      this.$store.dispatch('loginUser', { form: this.form }).then(({ statusCode, message }) => {
        if (statusCode === 200) {
          this.$toast.success(`Witamy na kotładzie 👋`);
          this.$router.push({ name: 'Home' });
        } else {
          if (message === 'password_mismatch') {
            this.$toast.error(`Podane hasło jest nieprawidłowe`);
          } else {
            this.$toast.error(`<strong>Upsss</strong><br>${message}`);
          }
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  .box-content {
    padding: 0 15px 15px 15px;
    max-width: 600px;
    width: 100%;
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  }
}
.flex-fill {
  flex: 1;
}
.password-recovery {
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
  }
}
</style>
