<template>
  <img v-if="mini" src="@/assets/images/logo/mini.png" alt="Logo" style="max-height: 50px">
  <img v-else src="@/assets/images/logo/main.svg" alt="Logo">
</template>

<script>
export default {
  name: "Logo",
  props: {
    mini: Boolean,
  },
}
</script>
