<template>
  <div class="checkbox_field">
    <input :id="`checkboxfield_${name}`" :checked="value[name] ? 'checked' : ''" @change="handleChange" :required="required ? 'required' : ''" type="checkbox">
    <label :for="`checboxfield_${name}`" v-html="labelProcessed" @click="handleLabelClick"></label>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  props: {
    value: Object,
    label: String,
    name: String,
    required: Boolean,
  },
  computed: {
    labelProcessed() {
      if (this.required) {
        return `<span class="required-star">*</span>${this.label}`;
      }
      return this.label;
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('input', {...this.value, [this.name]: e.target.checked});
    },
    handleLabelClick(e) {
      if (e.target.tagName === 'A') {
        return;
      }
      this.$emit('input', {...this.value, [this.name]: !this.value[this.name]});
    },
  },
}
</script>

<style scoped lang="scss">
  .checkbox_field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 6px 0;
    label {
      text-align: left;
      margin-left: 15px;
      font-size: 14px;
    }
    //input {
    //  align-self: stretch;
    //  text-align: center;
    //  background-color: var(--input-background);
    //  padding: 10px 12px;
    //  border-radius: 34px;
    //  margin: 8px 0;
    //  outline: none;
    //  border: none;
    //}
  }
</style>
