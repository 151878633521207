<template>
  <button class="btn" :class="{'btn-disabled': disabled}" :disabled="disabled" v-bind="$attrs" v-on="$listeners"><slot></slot></button>
</template>

<script>
export default {
  name: "Btn",
  props: {
    disabled: Boolean,
  }
}
</script>

<style scoped lang="scss">
  .btn {
    margin: 10px;
    background-color: var(--button-background);
    border-radius: 8px;
    border-width: 2px;
    border-color: var(--button-background);
    border-style: solid;
    padding: 10px 26px;
    overflow: hidden;
    text-align: center;
    color: var(--button-color);
    cursor: pointer;
    transition: background-color .2s ease, border-color .2s ease, opacity .2s ease;
    &:hover, &:focus {
      opacity: 0.75;
    }
    &.btn-disabled {
      background-color: var(--light-gray);
      border-color: var(--light-gray);
      &:hover, &:focus {
        opacity: 1;
      }
    }
  }
</style>
