<template>
  <div class="plain-footer">
    <span class="version">v{{ appVersion }}</span>
    <span class="copyright">Fastcat {{ year }} by <a href="https://appworks.pl/">AppWorks Software House Sp. z o.o.</a></span>
  </div>
</template>

<script>
import moment from 'moment';
import {version} from '../../package.json';

export default {
  name: "PlainFooter",
  data() {
    return {
      appVersion: version,
    };
  },
  computed: {
    year() {
      return moment().format('YYYY');
    }
  }
}
</script>

<style scoped lang="scss">
  .plain-footer {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    //position: sticky;
    width: calc(100% - 20px);
    padding: 0 10px;
    bottom: 0;
    font-size: 13px;
    background-color: var(--default-background);
    .version {
      font-size: 9px;
    }
    .copyright {
      padding-bottom: 4px;
      a {
        text-decoration: none;
        color: inherit;
        font-weight: 500;
      }
    }
  }
</style>
