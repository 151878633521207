<template>
  <div class="text_field">
    <label v-if="label" :for="`textfield_${name}`" v-html="labelProcessed"></label>
    <input :id="`textfield_${name}`" :type="inputType" :placeholder="placeholder" :value="value[name]" :required="required ? 'required' : ''" @input="handleInput" @keypress="$emit('keypress', $event)">
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    value: Object,
    label: String,
    name: String,
    placeholder: String,
    inputType: {
      type: String,
      default: 'text',
    },
    required: Boolean,
    format: String,
    maxLength: Number,
  },
  computed: {
    labelProcessed() {
      if (!this.label) {
        return '';
      }
      if (this.required) {
        return `${this.label}<span class="required-star">*</span>`;
      }
      return this.label;
    }
  },
  methods: {
    handleInput(e) {
      if (this.inputType === 'tel') {
        if (e.target.value && !(/^[0-9\s]{1,11}$/.test(e.target.value))) {
          this.$emit('input', {...this.value, [this.name]: this.value[this.name]});
          return;
        }
      }
      let val = e.target.value;
      if (this.format === 'uppercase') {
        val = val.toString().toUpperCase();
      }
      if (this.maxLength) {
        val = val.substr(0, this.maxLength);
      }
      this.$emit('input', {...this.value, [this.name]: val});
    },
  },
}
</script>

<style scoped lang="scss">
  .text_field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 6px 0;
    label {
      margin-left: 15px;
      font-size: 14px;
    }
    input {
      align-self: stretch;
      text-align: center;
      background-color: var(--input-background);
      padding: 10px 12px;
      border-radius: 34px;
      margin: 8px 0;
      outline: none;
      border: none;
    }
  }
</style>
