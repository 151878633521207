<template>
  <div class="page page--welcome animated_content">
    <sequential-entrance animation="animate__fadeInUp" tag="div">
      <DownloadFromStore class="animate__animated"/>
      <StickyHeader class="animate__animated"/>
      <div class="animate__animated">
        <Logo style="margin-top: 30px"/>
      </div>
      <div class="animate__animated">
        <h2 style="font-weight: normal;">Witaj w <strong>FASTCAT!</strong></h2>
      </div>
      <div class="animate__animated">
        <p>Tu zamawiasz = wspierasz.</p>
      </div>
      <div class="animate__animated">
        <p>Proste jak ogon i wąsy!</p>
      </div>
      <div class="animate__animated">
        <Btn @click.prevent="$router.push({name: 'Register'})">Załóż konto</Btn>
        <Btn @click.prevent="$router.push({name: 'Login'})">Zaloguj się</Btn>
      </div>
      <div class="animate__animated">
        <Btn @click.prevent="$router.push({name: 'Home'})">Kontynuuj bez logowania</Btn>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </sequential-entrance>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import DownloadFromStore from "@/parts/DownloadFromStore";

export default {
  name: "Welcome",
  components: {
    PlainFooter,
    StickyHeader,
    Logo,
    Btn,
    DownloadFromStore,
  },
  created() {
    if (window.localStorage.getItem('fastcat_welcome')) {
      this.$router.push({ name: 'Home' });
    } else {
      window.localStorage.setItem('fastcat_welcome', 'true');
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.flex-fill {
  flex: 1;
}
</style>
